<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="close">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 dark:bg-gray-800 dark:bg-opacity-80 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle  sm:max-w-sm sm:w-full bg-white dark:bg-gray-800 dark:border-amber-50 dark:border-2 sm:my-8">
            <slot></slot>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {ref, toRef, watch} from "vue";
const props = defineProps({
  open: {type: Boolean, default: () =>false},
  closable: { type: Boolean, default: () => true}
})
const emit = defineEmits(['close']);
const _open = toRef(props, 'open');
const open = ref(!!_open.value)
const closable = toRef(props, 'closable');
const close = ($event) => {
  if (closable.value) {
    open.value = false;
    emit('close')
  }
}

watch(_open, (newOpenValue, oldOpenValue) => {
  if (newOpenValue !== open.value) {
    open.value = newOpenValue;
  }
});
</script>
