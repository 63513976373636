<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ModalBase :open="open" :closable="closable" @close="close">
    <AuthenticateForms :tab="defaultTab" @loginSuccess="loginSuccess" @registerSuccess="registerSuccess" @forgotSuccess="forgotSuccess"/>
  </ModalBase>
</template>
<script setup>
import ModalBase from "~/components/modal/ModalBase.vue";
import { DialogTitle } from '@headlessui/vue'
import SignInLayout from "~/components/SignInLayout.vue";
import LoginForm from "~/components/user/LoginForm.vue";
import RegisterForm from "~/components/user/RegisterForm.vue";
import {toRef, ref, watch} from 'vue';
import AuthenticateForms from "~/components/user/AuthenticateForms.vue";

const props = defineProps({
  open: {type: Boolean, default: () => false},
  defaultTab: {type: String, default: () => 'login'},
  closable: { type: Boolean, default: () => true}
})

const emit = defineEmits(['close', 'loginSuccess', 'registerSuccess', 'forgotSuccess']);

const closable = toRef(props, 'closable');

const _open = toRef(props, 'open');
const open = ref(!!_open.value)
watch(_open, (o) => open.value = !!o)

const defaultTab = toRef(props, 'defaultTab');
const tab = ref(defaultTab.value+'')
watch(defaultTab, (t) => tab.value = t);

const close = () => {
  emit('close');
  open.value = false;
}

const loginSuccess = () => {
close();
emit('loginSuccess')
}
const registerSuccess = () => {
close();
emit('registerSuccess');
}
const forgotSuccess = () => {
close();
emit('forgotSuccess');
}

</script>
