import {computed, ref} from "vue";
import {useStrapiUserStore} from "~/stores/strapi-user.js";
import {useCartStore} from "~/stores/cart.js";
import {useProductsStore} from "~/stores/products.js";

export const useValidateCart = () => {
    const router = useRouter();
    const userStore = useStrapiUserStore();
    const cartStore = useCartStore();
    const productsStore = useProductsStore();
    const requiredLoginToCheckout = true;


    const loginOpen = ref(false)
    const orderItems = computed(() => cartStore.cart);
    const isAuthenticated = computed(() => userStore.isAuthenticated);

    const validating = ref(false);
    const validateCart = async () => {
        validating.value = true;
        try {
            // Ensure that product have the correct price => update the price instead
            await Promise.all(orderItems.value.map((orderItem) => productsStore.fetchProduct({id: orderItem.productId})));
            if (!isAuthenticated.value && requiredLoginToCheckout) {
                loginOpen.value = true;
            }
            else {
                validating.value = false;
                await router.push({name: 'cart'});
                open.value = false;
            }
        }
        catch (e) {
            console.error(e);
            validating.value = false;
        }


    }

    const loginClosed = async () => {
        loginOpen.value = false;
        try {
            await cartStore.saveCartRemotely();
        }
        catch (e) {
            console.error(e);
        }
        finally {
            if (validating.value) {
                validating.value = false;
            }
        }
        await router.push({name: 'cart'});
        open.value = false;
    };

    return {
        validateCart,
        validating,
        loginOpen,
        loginClosed
    }
}
